<template>
    <AppContainer>
        <edit-service/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import EditService from '@/components/dashboard/services/EditService.vue'
export default {
  name: 'AddNewService',
  components: {
    AppContainer,
    EditService
  }
}
</script>