<template>
<div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-8">
            <div class="service-tab-head">
                <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                    <li class="nav-item ">
                        <a v-on:click.prevent="nextStep(1,true)" class="nav-link" :class="step == 1 ? 'active':''" id="service-details-tab" data-toggle="tab" href="#serviceT_1" role="tab" aria-controls="service-details-tab" aria-selected="false">Service details</a>
                    </li>
                    <li class="nav-item ">
                        <a v-on:click.prevent="nextStep(2,true)" class="nav-link" :class="step == 2 ? 'active':''" id="pricing-duration-tab" data-toggle="tab" href="#serviceT_2" role="tab" aria-controls="serviceT_1" aria-selected="true">Pricing and Duration</a>
                    </li>
                    <li class="nav-item ">
                        <a v-on:click.prevent="nextStep(3,true)" class="nav-link" :class="step == 3 ? 'active':''" id="Assign-tab" data-toggle="tab" href="#serviceT_3" role="tab" aria-controls="Assign" aria-selected="true">Assign Professionals</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-8">
            <div class="serv-heading text-center">
                <h4 class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#videoModel">Learn how to add a service ( <fa icon="video" /> Watch video ) </h4>
            </div>
            <div id="collapseOne43" class="collapse show" aria-labelledby="headingOne43" data-parent="#accordionExample43">
                <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="step == 1 ? 'active show':''" id="serviceT_1" role="tabpanel" aria-labelledby="service-details-tab">
                            <!-- detais-title -->
                            <div class="row">
                                <div class="col-12 col-md-6">
                                <div class="blog-box">
                                    <div class="blog-heading"><h3>Service details</h3></div>
                                    <div class="blog-detail">
                                        <div class="form-group">
                                            <label class="fw-bold">Search service</label>
                                            <Multiselect v-model="service_.service" :class="[v$.service_.service.$error ? 'border-danger': '']" :options="services" @change="updateCate" class="form-control mt-2" placeholder='Search Service..' :searchable="true"></Multiselect>
                                        </div>
                                        <!-- <div class="form-group" v-show="service_.service ==null">
                                            <label for="inputAddress24">Our service not listed in search</label>
                                            <input :class="[v$.service_.service_title.$error ? 'border-danger': '']"  v-model="service_.service_title" type="text" class="form-control mt-1" placeholder="Type your service name" :disabled="service_.service !=null">
                                        </div> -->

                                        <div class="form-group">
                                            <label class="fw-bold" for="inputPassword4">Choose category</label>
                                            <select class="form-control mt-1" v-model="service_.category" :disabled="service_.service !=null" :class="[v$.service_.category.$error ? 'border-danger': '']">
                                                <option value="">Choose a category</option>
                                                <option v-for="cate in categories" :key="cate.id" :value="{id:cate.category_id,business_cate:cate.id}">{{cate.category.title}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="fw-bold">Short description <small style="font-size:12px">({{(100 - service_.description.length)}}/100)</small></label>
                                            <textarea :class="[v$.service_.description.$error ? 'border-danger': '']" class="form-control mt-1" v-model="service_.description" placeholder="You get a clean shave and haircut for this service"></textarea>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="blog-box">
                                        <div class="blog-detail">
                                            <div class="activate-service">
                                                <h3>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.onlyForBooking">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    For Booking Only
                                                </h3>
                                                <p><strong>Note:</strong> When button is not activated it means you are posting the service for both Queue and Schedule.</p>
                                                <h3 class="mt-4">
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.status">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    Service Status
                                                </h3>
                                                <!-- <p>This service is available for booking and queue. If you don't want it on queue make it enable.</p>
                                                
                                                <p><strong>Note:</strong> This service will be posted on your business profile if assign to a professional.</p> -->
                                                <div class="modal-footer campaign-footer d-flex">
                                                    <router-link :to="{name:'Services'}" class="add-btn"> Cancel</router-link>
                                                    <a href="#" class="rem-btn" v-on:click.prevent="nextStep(2,false)"> Next</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" :class="step == 2 ? 'active show':''" id="serviceT_2" role="tabpanel" aria-labelledby="pricing-duration-tab">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="blog-box">
                                        <div class="blog-heading"><h3>Pricing and Duration of service</h3></div>
                                        <div class="blog-detail">
                                            <div class="activate-service form-group">
                                                <h3>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.request_price">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    Requested For Price <fa icon="info-circle" class="ml-1 text-black-50" />
                                                </h3>
                                                <p><strong>Note:</strong> Using this feature allows you to get request for service with different descriptons, numbers or sizes.</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                    <label><span v-if="service_.request_price">Start Amount</span><span v-if="!service_.request_price">Price</span></label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"><fa icon="dollar-sign" /></div>
                                                        </div>
                                                            <input v-model="service_.price" :class="[v$.service_.price.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4" v-if="!service_.request_price">
                                                    <div class="form-group">
                                                    <label>Discounted price <fa icon="info-circle" class="text-black-50" /></label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"><fa icon="dollar-sign" /></div>
                                                        </div>
                                                            <input v-model="service_.discounted_price" type="text" class="form-control" placeholder="0">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="activate-service form-group">
                                                <h3>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.flex.status">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    Flex <fa icon="info-circle" class="ml-1 text-black-50" />
                                                </h3>
                                            </div>
   
                                            <div class="pr-listing" v-show="service_.flex.status">
                                                <ul>
                                                    <li>Initial: </li>
                                                    <li>
                                                        <div class="input-group">
                                                            <input v-model="service_.flex.initial" :class="[v$.service_.flex.initial.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Mins</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>Flex time: </li>
                                                    <li>
                                                        <div class="input-group">
                                                            <input v-model="service_.flex.delay" :class="[v$.service_.flex.delay.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Mins</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>Finish: </li>
                                                    <li>
                                                        <div class="input-group">
                                                            <input v-model="service_.flex.finish" :class="[v$.service_.flex.finish.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">Mins</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                            <div class="row mb-3" v-show="service_.flex.status">
                                                <div class="col-12">
                                                    <p><strong>Total duration:</strong> <span v-if="service_.flex.initial != '' && service_.flex.delay != '' && service_.flex.finish != ''">{{parseInt(service_.flex.initial)+parseInt(service_.flex.delay)+parseInt(service_.flex.finish)}}</span><span v-else>0</span> min </p>
                                                </div>
                                            </div>
                                            <div class="row" v-show="!service_.flex.status">
                                                <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                    <label>Duration (min)</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"><fa :icon="['far','clock']" /></div>
                                                        </div>
                                                            <input v-model="service_.duration" :class="[v$.service_.duration.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="activate-service form-group">
                                                <h3>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.tax.status">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    Apply Service Tax <fa icon="info-circle" class="ml-1 text-black-50" />
                                                </h3>
                                            </div>
                                            <div class="row" v-show="service_.tax.status">
                                                <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input v-model="service_.tax.value" :class="[v$.service_.tax.value.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="0">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text"><fa icon="percent" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-5">
                                                <div class="col-12 modal-footer campaign-footer">
                                                    <router-link :to="{name:'Services'}" class="add-btn float-left"> Cancel</router-link>
                                                    <a href="#" class="rem-btn float-right" v-on:click.prevent="nextStep(3,false)"> Next</a>
                                                    <a href="#" class="add-btn float-right" v-on:click.prevent="nextStep(1,true)"> Back</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" :class="step == 3 ? 'active show':''" id="serviceT_3" role="tabpanel" aria-labelledby="Assign-tab">
                            <!-- detais-title -->
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="blog-box">
                                        <div class="blog-heading">
                                            <h3>Professionals</h3>
                                            <p>Assign the professional(s) to this service</p>
                                        </div>
                                        <div class="blog-detail">
                                            <div class="row">
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group">
                                                        <label class="mb-1">Select the Professionals that offers this service </label>
                                                        <!-- <Multiselect v-model="service_.professionals" :options="employees" class="form-control mt-2" placeholder='Select professionals..' :searchable="true" mode="multiple" :closeOnSelect="false"></Multiselect> -->
                                                        <Multiselect
                                                            v-model="service_.professionals"
                                                            mode="tags"
                                                            placeholder="Select employees"
                                                            trackBy="name"
                                                            label="name"
                                                            :closeOnSelect="false"
                                                            :search="true"
                                                            :options="employees"
                                                        >
                                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                            <div class="multiselect-tag is-user">
                                                                <img :src="option.image">
                                                                {{ option.name }}
                                                                <span
                                                                v-if="!disabled"
                                                                class="multiselect-tag-remove"
                                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                                                >
                                                                <span class="multiselect-tag-remove-icon"></span>
                                                                </span>
                                                            </div>
                                                            </template>
                                                        </Multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Blog Detail -->

                                        <div class="box-bottom">
                                            <!-- <p><strong>Pay Commission</strong> <fa icon="info-circle" class="text-black-50" /></p>
                                            <p>Your professional will get commission when service is completed, deactivate if your’re paying salary</p>
                                            <div class="activate-service form-group">
                                                <h3>
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="service_.commission">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    Activate commission 
                                                </h3>
                                            </div> -->
                                            <div class="row mt-5">
                                                <div class="col-12 modal-footer campaign-footer">
                                                    <router-link :to="{name:'Services'}" class="add-btn float-left"> Cancel</router-link>
                                                    <a href="#" class="rem-btn float-right" v-on:click.prevent="updateService()"> Update</a>
                                                    <a href="#" class="add-btn float-right" v-on:click.prevent="nextStep(2,true)"> Back</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Blog box -->
                                </div>
                            </div><!-- end row -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="videoModel">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Learn how to add a service</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <video src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/how.mp4" class="w-100" loop autoplay muted controls></video>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="ond-btn-w" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required,requiredIf,between,numeric } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
export default {
    components: {
        Multiselect
    },
    setup () {
        const toast = useToast();
        return { v$: useVuelidate(),toast }
    },
    data() {
        return {
            service_detail:{},
            professionals_assigned:[],
            step:1,
            services:[],
            categories:[],
            employees:[],
            service_:{
                id:'',
                service:null,
                category:'',
                description:'',
                status:true,
                price:'',
                discounted_price:'',
                duration:'',
                flex:{
                    status:false,
                    initial:'',
                    delay:'',
                    finish:''
                },
                tax:{
                    status:false,
                    value:''
                },
                professionals:[],
                commission:false,
                onlyForBooking:false,
                request_price:false
            }
        }
    },
    validations () {
        return {
            service_:{
                service: { required },
                category:{ required },
                description:{ required },
                price:{ required },
                duration: {
                    requiredIfFoo: requiredIf(this.service_.flex.status === false),
                },
                flex:{
                    initial: {requiredIfFoo:requiredIf(this.service_.flex.status === true),numeric},
                    delay: {requiredIfFoo:requiredIf(this.service_.flex.status === true),numeric},
                    finish: {requiredIfFoo:requiredIf(this.service_.flex.status === true),numeric},
                },
                tax:{
                    value: {requiredIfFoo:requiredIf(this.service_.tax.status === true),betweenValue: between(0, 100),numeric},
                }
            }
        }
    },
    created() {
        this.getserviceDetail()
        this.servicesList()
        this.categoriesList()
        this.employeesList()
    },
    methods:{
        async getserviceDetail(){
            let thiss = this
            await axios.get('businessServices/'+this.$route.params.id).then(function (response) {
                if (response.data.status == 'success') {
                    console.log(response.data.data)
                    thiss.service_detail = response.data.data
                    thiss.service_.id = thiss.service_detail.id
                    thiss.service_.description = thiss.service_detail.description
                    thiss.service_detail.status == 1 ? thiss.service_.status=true:thiss.service_.status=false;
                    if (thiss.service_detail.org_cost > 0) {
                        thiss.service_.price = thiss.service_detail.org_cost
                        thiss.service_.discounted_price = thiss.service_detail.cost
                    }else{
                        thiss.service_.price = thiss.service_detail.cost
                    }
                    if (thiss.service_detail.initial_duration == null || thiss.service_detail.initial_duration == '' || thiss.service_detail.initial_duration == 'undefined' || thiss.service_detail.initial_duration == undefined) {
                        thiss.service_.duration = thiss.service_detail.duration
                    }else{
                        thiss.service_.flex.status = true
                        thiss.service_.flex.initial = thiss.service_detail.initial_duration
                        thiss.service_.flex.delay = thiss.service_detail.gap
                        thiss.service_.flex.finish = thiss.service_detail.finish_duration
                    }
                    if (thiss.service_detail.tax > 0) {
                        thiss.service_.tax.status = true
                        thiss.service_.tax.value = thiss.service_detail.tax
                    }
                    thiss.service_detail.commission == 1 ? thiss.service_.commission=true:thiss.service_.commission=false;
                    thiss.service_detail.only_for_booking == 1 ? thiss.service_.onlyForBooking=true:thiss.service_.onlyForBooking=false;
                    thiss.service_detail.is_requested == 1 ? thiss.service_.request_price=true:thiss.service_.request_price=false;

                    if (thiss.service_detail.professionals.length > 0) {
                        thiss.service_detail.professionals.forEach((pro) => {
                            thiss.professionals_assigned.push(pro.employee_id)
                        })
                    }

                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        this.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        },
        servicesList(){
            let thiss = this
            axios.get('businessCategories?action=with_services').then(function (response) {
                if (response.data.status == 'success') {
                    var data = []
                    response.data.data.forEach((cate) => {
                        cate.services.forEach((serv) => {
                            serv.business_category_id = cate.business_category.id
                            data.push({ value:serv, label: serv.title })
                            if (thiss.service_detail.service_id == serv.id) {
                                thiss.service_.service = serv
                                thiss.updateCate(serv)
                            }
                        });
                    });
                    thiss.services = data
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        this.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        },
        async nextStep(stepNum,isValid){
            if(isValid){
                this.step = stepNum
            }else{
                if (stepNum == 2) {
                    await this.v$.service_.service.$validate()
                    await this.v$.service_.category.$validate()
                    await this.v$.service_.description.$validate()
                    if (!this.v$.service_.service.$error  && !this.v$.service_.category.$error  && !this.v$.service_.description.$error) {
                        this.step = stepNum
                    }
                }else if (stepNum == 3) {
                    await this.v$.service_.price.$validate()
                    await this.v$.service_.duration.$validate()
                    await this.v$.service_.flex.initial.$validate()
                    await this.v$.service_.flex.delay.$validate()
                    await this.v$.service_.flex.finish.$validate()
                    await this.v$.service_.tax.value.$validate()

                    if (!this.v$.service_.price.$error && !this.v$.service_.duration.$error  && !this.v$.service_.flex.initial.$error  && !this.v$.service_.flex.delay.$error && !this.v$.service_.flex.finish.$error && !this.v$.service_.tax.value.$error) {
                        this.step = stepNum
                    }
                }
            }
        },
        categoriesList(){
            let thiss = this
            axios.get('businessCategories').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.categories = response.data.data
                }
                //console.log(thiss.categories)
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        this.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        },
        employeesList(){
            let thiss = this
            axios.get('employees_list').then(function (response) {
                //console.log(response.data.data)
                if (response.data.data.length > 0) {
                    var data = []
                    response.data.data.forEach((item) => {
                        data.push({ value: item, name: item.user.name, image:"http://localhost:8080/img/Anne_Hathaway_Face.bbdbcaa5.jpg" })
                        if (thiss.professionals_assigned.includes(item.id)) {
                            thiss.service_.professionals.push(item)
                        }
                    });
                    thiss.employees = data
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }
                }
            });
        },
        updateCate(value){
            if(value){
                this.service_.category = {id:value.category_id,business_cate:value.business_category_id}
            }else{
                this.service_.category = ''
            }
        },
        updateService(){
            const isFormValid = this.v$.service_.$validate()
            if (isFormValid) {
                console.log(this.service_)
                let thiss = this
                axios.post('addNewService',this.service_).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.$router.push({ name: 'Services'})
                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }
                    }
                });
            }
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
    .multiselect-tag.is-user {
        padding: 5px 8px;
        border-radius: 22px;
        background: #35495e;
        margin: 3px 3px 8px;
    }

    .multiselect-tag.is-user img {
        width: 18px;
        border-radius: 50%;
        height: 18px;
        margin-right: 8px;
        border: 2px solid #ffffffbf;
    }

    .multiselect-tag.is-user i:before {
        color: #ffffff;
        border-radius: 50%;;
    }

    .user-image {
        margin: 0 6px 0 0;
        border-radius: 50%;
        height: 22px;
    }
</style>